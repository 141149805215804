export const STUDENT_WIDTH = 210;

export const SCREEN_WIDTH = 180;

export const BADGE_ATTENDANCE_WIDTH = 340;
export const ATTENDANCE_MIN_WIDTH = 540;
export const ATTENDANCE_READONLY_WIDTH = 150;

export const BADGE_GRADING_WIDTH = 220;
export const GRADING_MIN_WIDTH = 460;
export const GRADING_READONLY_WIDTH = 180;

export const NOTES_MIN_WIDTH = 250;
