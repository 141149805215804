import {
  EngagementStudentGrades_PageDataFragment,
  GradeLevel,
  StudentGradesTable_StudentGradesPeriodAveragesFragment,
  StudentGradesTable_StudentGradesRecordFragment,
} from "@generated/graphql";

export enum StudentGradesSortType {
  StudentGrade = "Student Grades",
  Attendance = "Attendance",
  Student = "Student",
  Cohorts = "Cohorts",
  Grades = "Grades",
}

export type StudentGradesSort = {
  isSortedDesc: boolean;
  sortType: StudentGradesSortType;
};

export type StudentGradesFilters = {
  endDateFilter: Date | null;
  startDateFilter: Date | null;
  cohortIdFilter: string | null;
  showRemovedStudentsFilter: boolean;
  studentGradeFilter: GradeLevel | null;
};

export type StudentGradesPageData = EngagementStudentGrades_PageDataFragment;

export type StudentGradesPageCohort = StudentGradesPageData["cohorts"][0];
export type StudentGradesPageStudent = StudentGradesPageData["students"][0];
export type StudentGradesPageEngagement = StudentGradesPageData["engagement"];

export type StudentGradesCohortMap = {
  [cohortId: string]: StudentGradesPageCohort;
};

export type StudentDayDataMap = {
  [isoDayDate: string]: StudentGradesTable_StudentGradesRecordFragment[];
};

export type StudentGradesWeekDataMap = {
  [studentId: string]: StudentDayDataMap;
};

export type StudentGradesAveragesDataMap = {
  [studentId: string]: StudentGradesTable_StudentGradesPeriodAveragesFragment;
};
