import { useLayout } from "@contexts/LayoutProvider";
import {
  CohortAssignmentSubject,
  StudentGradesPageData,
} from "@generated/graphql";
import { SelectMenuOption } from "@shared";
import {
  ExclusionISODays,
  SubjectComboWithAll,
  WeekdayData,
  WidthsMap,
} from "@shared/AttendanceGrades/types";
import {
  buildWeekdayData,
  getGradeFilterOptions,
} from "@shared/AttendanceGrades/utils";
import { CellRendererHoliday } from "@shared/NavigationCalendars";
import { isEqual } from "lodash";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { flushSync } from "react-dom";
import {
  COLUMN_MAP,
  initExclusionISODays,
  initFilters,
  initSort,
  SIDE_MENU_PADDING,
} from "./constants";
import {
  StudentGradesCohortMap,
  StudentGradesFilters,
  StudentGradesPageEngagement,
  StudentGradesPageStudent,
  StudentGradesSort,
  StudentGradesSortType,
} from "./types";
import {
  getCohortColWidth,
  getCohortMap,
  getColumnWidths,
  getFilteredStudents,
  sortStudentsByStudentDetails,
} from "./utils";

const initialState = {
  gradeOptions: [],
  weekdaysData: [],
  cohortOptions: [],
  isPublicPage: true,
  widthsMap: {} as WidthsMap,
  exclusionISODays: initExclusionISODays,
  cohortsMap: {} as StudentGradesCohortMap,
  engagement: {} as StudentGradesPageEngagement,
  engagementHolidaysMap: {} as Map<string, CellRendererHoliday[]>,
  subjectCombo: { subject: CohortAssignmentSubject.General, subSubject: null },

  activeStudents: [],
  setActiveStudents: () => {},

  weekViewDate: new Date(),
  setWeekViewDate: () => {},

  isCalculating: true,
  setIsCalculating: () => {},

  studentGradesSort: initSort,
  setStudentGradesSort: () => {},

  studentGradesFilters: initFilters,
  setStudentGradesFilters: () => {},

  studentPanelWidth: 550,
  weekdayPanelWidth: 600,
};

type StudentGradesDataContextType = {
  widthsMap: WidthsMap;
  isPublicPage: boolean;
  weekdaysData: WeekdayData[];
  gradeOptions: SelectMenuOption[];
  cohortOptions: SelectMenuOption[];
  subjectCombo: SubjectComboWithAll;
  cohortsMap: StudentGradesCohortMap;
  engagement: StudentGradesPageEngagement;
  engagementHolidaysMap: Map<string, CellRendererHoliday[]>;

  activeStudents: StudentGradesPageStudent[];
  setActiveStudents: (students: StudentGradesPageStudent[]) => void;

  weekViewDate: Date;
  setWeekViewDate: (date: Date) => void;

  isCalculating: boolean;
  setIsCalculating: (isCalculating: boolean) => void;

  studentGradesSort: StudentGradesSort;
  setStudentGradesSort: (sort: StudentGradesSort) => void;

  studentGradesFilters: StudentGradesFilters;
  setStudentGradesFilters: (data: StudentGradesFilters) => void;

  studentPanelWidth: number;
  weekdayPanelWidth: number;
};

export const StudentGradesDataContext =
  createContext<StudentGradesDataContextType>(initialState);

type Props = {
  children: ReactNode;
  isPublicPage: boolean;
  pageData: StudentGradesPageData;
  subjectCombo: SubjectComboWithAll;
  exclusionISODays: ExclusionISODays;
  engagementHolidaysMap: Map<string, CellRendererHoliday[]>;
};

export const StudentGradesDataProvider = ({
  children,
  pageData,
  isPublicPage,
  exclusionISODays,
  subjectCombo,
  engagementHolidaysMap,
}: Props) => {
  const { screenWidth } = useLayout();
  const { cohorts, students, engagement } = pageData;

  const [isCalculating, setIsCalculating] = useState(true);

  const initCalcAndDeferFn = (fn: () => void) => {
    flushSync(() => setIsCalculating(true)); // Forces immediate update
    setTimeout(fn, 0); // Pushes function to next event loop
  };

  const gradeOptions = useMemo(() => {
    return getGradeFilterOptions(students);
  }, [students]);

  const [weekViewDate, setViewDate] = useState(new Date());
  const setWeekViewDate = useCallback((date: Date) => {
    initCalcAndDeferFn(() => setViewDate(date));
  }, []);

  const weekdaysData: WeekdayData[] = useMemo(() => {
    return buildWeekdayData(
      new Date(engagement.startDate),
      new Date(engagement.endDate),
      weekViewDate,
      exclusionISODays,
      cohorts.map(({ id }) => id)
    );
  }, [cohorts, engagement, weekViewDate, exclusionISODays]);

  const [studentGradesFilters, setFilters] =
    useState<StudentGradesFilters>(initFilters);

  const setStudentGradesFilters = useCallback(
    (filters: StudentGradesFilters) => {
      const { startDateFilter: newStart, endDateFilter: newEnd } = filters;
      const { startDateFilter, endDateFilter } = studentGradesFilters;

      if (!isEqual(filters, studentGradesFilters))
        isEqual(newStart, startDateFilter) && isEqual(newEnd, endDateFilter)
          ? initCalcAndDeferFn(() => setFilters(filters))
          : setFilters(filters);
    },
    [studentGradesFilters]
  );

  const {
    endDateFilter,
    cohortIdFilter,
    startDateFilter,
    studentGradeFilter,
    showRemovedStudentsFilter,
  } = studentGradesFilters;

  useEffect(() => {
    setFilters({ ...initFilters, startDateFilter, endDateFilter });
  }, [endDateFilter, startDateFilter, subjectCombo]);

  const { cohortsMap, cohortOptions } = useMemo(() => {
    return getCohortMap(cohorts, subjectCombo, cohortIdFilter);
  }, [cohorts, subjectCombo, cohortIdFilter]);

  const [activeStudents, setActiveStudents] =
    useState<StudentGradesPageStudent[]>(students);

  useEffect(() => {
    setActiveStudents(
      getFilteredStudents(
        students,
        cohortsMap,
        weekdaysData,
        cohortIdFilter,
        studentGradeFilter,
        showRemovedStudentsFilter,
        engagement
      )
    );
  }, [
    students,
    cohortsMap,
    engagement,
    weekdaysData,
    cohortIdFilter,
    studentGradeFilter,
    showRemovedStudentsFilter,
  ]);

  const [studentGradesSort, setSort] = useState<StudentGradesSort>(initSort);
  const setStudentGradesSort = useCallback(
    (sort: StudentGradesSort) => {
      if (!isEqual(studentGradesSort, sort))
        initCalcAndDeferFn(() => setSort(sort));
    },
    [studentGradesSort]
  );

  useEffect(() => {
    const getSortedStudentsByStudentDetails = async () => {
      const sortedStudents = await sortStudentsByStudentDetails(
        activeStudents,
        cohortsMap,
        studentGradesSort
      );
      setActiveStudents([...sortedStudents]);
    };

    const { sortType } = studentGradesSort;
    const { Attendance, Grades } = StudentGradesSortType;
    const isSortedByDetails = sortType !== Grades && sortType !== Attendance;

    if (isSortedByDetails) getSortedStudentsByStudentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentGradesSort]);

  const { studentPanelWidth, weekdayPanelWidth, widthsMap } = useMemo(() => {
    const colWidths = getColumnWidths(
      screenWidth,
      getCohortColWidth(cohortsMap)
    );

    const widthsMap: WidthsMap = COLUMN_MAP.reduce((map, key, index) => {
      map[key] = colWidths[index];
      return map;
    }, {} as WidthsMap);

    const studentPanelWidth = colWidths.reduce((acc, width) => acc + width, 0);
    const weekdayPanelWidth =
      screenWidth - studentPanelWidth - SIDE_MENU_PADDING;

    return { studentPanelWidth, weekdayPanelWidth, widthsMap };
  }, [screenWidth, cohortsMap]);

  useEffect(() => {
    setTimeout(() => setIsCalculating(false), 0);
  }, [activeStudents, setIsCalculating]);

  const contextValue = useMemo(
    () => ({
      widthsMap,
      engagement,
      cohortsMap,
      isPublicPage,
      gradeOptions,
      weekdaysData,
      subjectCombo,
      cohortOptions,
      engagementHolidaysMap,

      activeStudents,
      setActiveStudents,

      weekViewDate,
      setWeekViewDate,

      isCalculating,
      setIsCalculating,

      studentGradesSort,
      setStudentGradesSort,

      studentGradesFilters,
      setStudentGradesFilters,

      studentPanelWidth,
      weekdayPanelWidth,
    }),
    [
      widthsMap,
      cohortsMap,
      engagement,
      gradeOptions,
      isPublicPage,
      subjectCombo,
      weekViewDate,
      weekdaysData,
      cohortOptions,
      isCalculating,
      activeStudents,
      setWeekViewDate,
      studentGradesSort,
      studentPanelWidth,
      weekdayPanelWidth,
      setStudentGradesSort,
      studentGradesFilters,
      engagementHolidaysMap,
      setStudentGradesFilters,
    ]
  );

  return (
    <StudentGradesDataContext.Provider value={contextValue}>
      {children}
    </StudentGradesDataContext.Provider>
  );
};

export const useStudentGradesData = () =>
  React.useContext(StudentGradesDataContext);
