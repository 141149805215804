import {
  CohortAssignmentSubject,
  CohortSessionStudentAttendanceStatus,
} from "@generated/graphql";
import { GradingPanel } from "@shared/AttendanceGrades/components/GradingPanel/GradingPanel";
import { SessionReportStudentEvaluationRow } from "@shared/SessionReports/types";
import clsx from "clsx";
import { GRADING_READONLY_WIDTH } from "../../..";

type Props = {
  studentGradingIsEnabled: boolean;
  subject: CohortAssignmentSubject;
  grading: SessionReportStudentEvaluationRow["grading"];
  attendanceStatus: CohortSessionStudentAttendanceStatus;
};

export const ReadOnlyGradingPanel = ({
  grading,
  subject,
  attendanceStatus,
  studentGradingIsEnabled,
}: Props) => {
  const { Absent, Unknown } = CohortSessionStudentAttendanceStatus;
  const isAbsentStatus = attendanceStatus === Absent;

  return studentGradingIsEnabled ? (
    <div
      className="flex text-sm font-semibold text-slate-600 !my-[6px]"
      style={{ width: `${GRADING_READONLY_WIDTH}px` }}
    >
      {grading && ![Absent, Unknown].includes(attendanceStatus) ? (
        <GradingPanel
          readOnly={true}
          subject={subject}
          key={grading?.id}
          disableGrading={true}
          studentGrades={grading}
          updateStudentGrade={() => {}}
        />
      ) : (
        <div
          className={clsx(
            "font-normal",
            isAbsentStatus ? "pl-[60px] text-gray-600" : "text-gray-300"
          )}
        >
          {isAbsentStatus ? "--" : "No Grade"}
        </div>
      )}
    </div>
  ) : null;
};
