import { tableTitleTextStyles } from "@utils/styleStrings";
import clsx from "clsx";
import { NOTES_MIN_WIDTH, SCREEN_WIDTH, STUDENT_WIDTH } from "../";

type Props = {
  readOnly: boolean;
  gradesColWidth: number;
  isGradingEnabled: boolean;
  attendanceColWidth: number;
};

export const SessionStudentEvaluationTableHeader = ({
  readOnly,
  gradesColWidth,
  isGradingEnabled,
  attendanceColWidth,
}: Props) => (
  <div
    className={clsx(
      "flex items-center w-full h-[36px] bg-slate-100/80 rounded-t-lg",
      tableTitleTextStyles
    )}
  >
    <div className={clsx("flex pl-4")} style={{ width: `${STUDENT_WIDTH}px` }}>
      Student
    </div>

    {readOnly && (
      <div
        className="flex justify-center"
        style={{ width: `${SCREEN_WIDTH}px` }}
      >
        Screen Name
      </div>
    )}

    <div
      className={clsx("flex justify-center", !readOnly && "flex-1")}
      style={{
        minWidth: `${attendanceColWidth}px`,
        width: readOnly ? `${attendanceColWidth}px` : "w-auto",
      }}
    >
      <span
        style={{ paddingRight: `${readOnly ? 0 : attendanceColWidth - 156}px` }}
      >
        Attendance
      </span>
    </div>

    {isGradingEnabled && (
      <div
        className={clsx("flex", !readOnly && "flex-1")}
        style={{
          minWidth: `${gradesColWidth}px`,
          width: readOnly ? `${gradesColWidth}px` : "auto",
        }}
      >
        Grades
      </div>
    )}

    {readOnly && (
      <div className="flex flex-1" style={{ minWidth: `${NOTES_MIN_WIDTH}px` }}>
        Notes
      </div>
    )}
  </div>
);
