import { AbsentType, GradeType } from "../../types";
import { StudentGradePanelGrades } from "./types";

export const hasSomeTruthyGrades = (
  grades: StudentGradePanelGrades,
  gradeOptions: GradeType[]
) =>
  gradeOptions.some(
    (type) => grades[type] !== null && grades[type] !== undefined
  );

export const hasSomeAbsentGrades = (
  grades: StudentGradePanelGrades,
  absentOptions: AbsentType[]
) => absentOptions.some((type) => grades[type] !== false);
