import { gql } from "@apollo/client";
import { GradingPanel } from "../GradingPanel/GradingPanel";

export const STUDENT_FRAGMENT = gql`
  fragment SessionStudentEvaluationTable_TutorDashboardStudent on TutorDashboardStudent {
    id
    fullName
    studentId
    removedAt
    startDate
    studentStatus
  }
`;

export const ATTENDANCE_FRAGMENT = gql`
  fragment SessionStudentEvaluationTable_StudentAttendanceRecord on TutorDashboardCohortSessionStudentAttendance {
    id
    notes
    status
    studentId
    displayName
  }
`;

export const GRADING_FRAGMENT = gql`
  fragment SessionStudentEvaluationTable_StudentGradingRecord on TutorDashboardCohortSessionStudentGrading {
    id
    studentId
    readingGrade
    classworkGrade
    languageArtsGrade
    participationGrade
    readingAbsentFromAssessment
    classworkAbsentFromAssessment
    languageArtsAbsentFromAssessment
    ...GradingPanel_StudentGradingRecord
  }
  ${GradingPanel.fragments.tutorDashboardCohortSessionStudentGrading}
`;
