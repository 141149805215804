import { AttendanceStatus } from "types/global";
import { statusButtonOptions } from "../constants";
import { AttendanceStatusPillButton } from "./AttendanceStatusPillButton";

type Props = {
  loading?: boolean;
  status: AttendanceStatus;
  statusOptions?: AttendanceStatus[];
  updateStudentAttendance: (status: AttendanceStatus) => Promise<void>;
};

export const AttendanceStatusButtonsPanel = ({
  status,
  loading = false,
  statusOptions = statusButtonOptions,
  updateStudentAttendance,
}: Props) =>
  statusOptions.map((optionStatus, i) => (
    <AttendanceStatusPillButton
      loading={loading}
      status={optionStatus}
      key={`${i}-${status}`}
      isSelected={optionStatus == status}
      onChange={updateStudentAttendance}
    />
  ));
