import { Icon } from "@shared/Icon";

type Props = { displayName: string };

export const SavedDisplayNameIndicator = ({ displayName }: Props) => (
  <div className="flex flex-row gap-x-2">
    <Icon icon="db" size={4} color="text-emerald-700" />
    <span className="text-emerald-700 font-semibold">{displayName}</span>
  </div>
);
