import { useAuth } from "@contexts/AuthProvider";
import { CohortAssignmentSubject } from "@generated/graphql";
import { cohortSubjectOptions } from "@shared/AttendanceGrades/constants";
import {
  ANY_SUB_SUBJECT,
  SubjectComboWithAll,
  SubjectOption,
  SubSubjectAllOption,
} from "@shared/AttendanceGrades/types";
import {
  getSubjectComboKey,
  getSubSubjectAllOptions,
} from "@shared/AttendanceGrades/utils";
import { Icon, SelectMenu, Tooltip } from "@shared/index";
import { Routes } from "@utils/routes";
import { useStudentGradesData } from "contexts/StudentGradesDataProvider";
import { useMemo } from "react";

export const SubjectSelector = () => {
  const { setActiveRoute } = useAuth();
  const { subjectCombo, engagement, isPublicPage } = useStudentGradesData();

  const { subject, subSubject } = subjectCombo;
  const { id, allSubjectOptions, limitedAccessKey } = engagement;
  const subjectIsGeneral = subject === CohortAssignmentSubject.General;

  const handleSubjectRedirect = (subjectCombo: SubjectComboWithAll) => {
    const key = isPublicPage ? limitedAccessKey ?? "unknownKey" : id;
    const routeKey = isPublicPage ? "publicStudentGrades" : "studentGrades";
    const route = Routes.engagement[routeKey].subjectSelected;
    setActiveRoute(route, [key, getSubjectComboKey(subjectCombo)], true);
  };

  const subjectOptions: SubjectOption[] = useMemo(() => {
    const subjectOptions = cohortSubjectOptions.filter(({ subject }) =>
      allSubjectOptions.includes(subject)
    );
    // In case the Engagement has no subjects, our fallback is to show the General subject.
    if (subjectOptions.length === 0) {
      return [
        {
          id: CohortAssignmentSubject.General,
          value: CohortAssignmentSubject.General,
          subject: CohortAssignmentSubject.General,
        },
      ];
    }
    return subjectOptions;
  }, [allSubjectOptions]);

  const subSubjectOptions: SubSubjectAllOption[] = useMemo(() => {
    return getSubSubjectAllOptions(subject).filter(({ subSubject }) =>
      engagement.allSubSubjectOptions.some(
        (cohortSubSubject: string) =>
          subSubject === ANY_SUB_SUBJECT || cohortSubSubject === subSubject
      )
    );
  }, [engagement.allSubSubjectOptions, subject]);

  const subjectIndex = useMemo(() => {
    return subjectOptions.findIndex((o) => o.subject === subject);
  }, [subject, subjectOptions]);

  const subSubjectIndex = useMemo(() => {
    return subSubjectOptions.findIndex((o) => o.subSubject === subSubject);
  }, [subSubject, subSubjectOptions]);

  return (
    <>
      <Tooltip
        tooltipProps={{ place: "right" }}
        className="flex w-full items-center justify-between"
        content="The subject (and optionally sub-subject) are used to filter the data shown in the table. Displayed stats will adjust accordingly. Students whose grading data falls outside the filter will not be shown."
        contentClassName="text-sm max-w-[32ch]"
      >
        <div className="w-full flex items-center justify-between">
          <label>Subject</label>
          <Icon icon="info" size={5} />
        </div>
      </Tooltip>
      <div className="flex items-center gap-x-1 w-full col-span-2">
        <SelectMenu
          options={subjectOptions}
          onSelect={(option) => {
            if (option.id !== subject)
              handleSubjectRedirect({
                subject: option.subject,
                subSubject: null,
              });
          }}
          initialIndex={subjectIndex}
          className="flex flex-1 min-w-[100px]"
          listAlignment="left"
          heightPx={32}
        />
        {subSubjectOptions?.length > 0 && !subjectIsGeneral && (
          <SelectMenu
            options={subSubjectOptions}
            onSelect={(option) => {
              if (option.id !== subSubject) {
                handleSubjectRedirect({
                  subject: subject ?? subjectOptions[0].subject,
                  subSubject:
                    option.subSubject !== ANY_SUB_SUBJECT
                      ? option.subSubject
                      : null,
                });
              }
            }}
            initialIndex={subSubjectIndex}
            className="flex flex-1 min-w-[100px]"
            listAlignment="left"
            heightPx={32}
          />
        )}
      </div>
    </>
  );
};
