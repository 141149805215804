import { CohortSessionStudentAttendanceStatus } from "@generated/graphql";
import { getAttendanceStatusText } from "@utils/labels";
import clsx from "clsx";
import { AttendanceStatus } from "types/global";
import { PillButton } from "../../Buttons/PillButton";
import { Spinner } from "../../Loading/Spinner";
import { getAttendanceStatusStyles } from "../utils";

type Props = {
  loading?: boolean;
  className?: string;
  isSelected?: boolean;
  status: AttendanceStatus;
  onChange?: (status: AttendanceStatus) => void;
};

export const AttendanceStatusPillButton = ({
  status,
  className,
  loading = false,
  isSelected = true,
  onChange,
}: Props) => (
  <PillButton
    key={status}
    className={clsx(
      "relative border",
      isSelected
        ? `${getAttendanceStatusStyles(status).pillColor} border-2 text-white`
        : "border-gray-300 bg-white text-gray-700",
      className
    )}
    name={
      <div className={clsx(!isSelected && "border border-white")}>
        {loading && (
          <Spinner
            color="text-blue-700"
            size={4}
            className="absolute right-[calc(50%-8px)]"
          />
        )}
        <div className={clsx(loading && "opacity-0")}>
          {getAttendanceStatusText(status)}
        </div>
      </div>
    }
    onChange={() =>
      onChange?.(
        isSelected ? CohortSessionStudentAttendanceStatus.Unknown : status
      )
    }
  />
);
