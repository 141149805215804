type Props = {
  eventsCount: number;
  presentOrPartialCount: number;
};

export const AttendanceTooltipContent = ({
  eventsCount,
  presentOrPartialCount,
}: Props) => (
  <div className="flex flex-col flex-center">
    <span className="text-lg">{`${presentOrPartialCount} / ${eventsCount}`}</span>
    <span>Attended Events / Total Events</span>
  </div>
);
