import { StudentStatus } from "@generated/graphql";
import { Link } from "@shared/Link";
import { getNormalizedShortReadableDate } from "helpers/dateText";

export const getStudentStatusTooltip = (
  studentStatus: StudentStatus,
  studentStart: Date
) => (
  <div className="w-[170px] flex flex-col flex-center">
    <div>{studentStatus === StudentStatus.New ? "NEW" : "TRANSFERRED"}</div>
    <div>
      {`Start Date: ${getNormalizedShortReadableDate(new Date(studentStart))}`}
    </div>
  </div>
);

export const renderConditionalLink = (
  label: string,
  href: string,
  hideLink: boolean
) =>
  hideLink ? (
    <span className="font-semibold text-gray-700 leading-none">{label}</span>
  ) : (
    <Link className="leading-none" href={href}>
      {label}
    </Link>
  );
