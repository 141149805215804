import { CohortAssignmentSubject } from "@generated/graphql";
import { SubjectButtonList } from "@shared/AttendanceGrades/components/SubjectButtonList";
import { Button, Container, ErrorBox, Page } from "@shared/index";
import { Route } from "@utils/routes/types";
import { useAuth } from "contexts/AuthProvider";

type Props = {
  msg: string;
  route: Route;
  routeProps?: string[];
  subjects: CohortAssignmentSubject[];
  limitedAccessKey?: string;
  engagementId: string;
  publicPage: boolean;
};

export const InvalidSubjectKeyComboSection = ({
  msg,
  route,
  subjects,
  routeProps,
  limitedAccessKey,
  engagementId,
  publicPage,
}: Props) => {
  const { setActiveRoute } = useAuth();

  return (
    <Page>
      <Container flex>
        {subjects.length > 0 && (
          <SubjectButtonList
            title="Available Subjects"
            subjects={subjects}
            publicPage={publicPage}
            engagementId={engagementId}
            limitedAccessKey={limitedAccessKey ?? ""}
          />
        )}

        <ErrorBox msg={msg} />

        <Button
          className="w-fit ml-auto justify-end"
          onClick={() => setActiveRoute(route, routeProps)}
        >
          Return
        </Button>
      </Container>
    </Page>
  );
};
