import { Checkbox, Icon, Tooltip } from "@shared";

type Props = {
  showRemovedStudents: boolean;
  updateFilters: () => void;
};

export const ShowRemovedStudentsCheckbox = ({
  showRemovedStudents,
  updateFilters,
}: Props) => (
  <Tooltip
    tooltipProps={{ place: "right" }}
    className="flex items-center cursor-pointer text-sm font-semibold text-gray-800"
    content={
      <div className="flex flex-col w-[300px] text-center gap-y-2 leading-tight">
        <p>
          Select to see ALL students and cohort assignments for the entire
          engagement (both active and removed)
        </p>
      </div>
    }
  >
    <div
      className="flex h-full items-center gap-x-[5px]"
      onClick={updateFilters}
    >
      <Checkbox checked={showRemovedStudents} />
      Show Removed Students
      <Icon icon="info" size={4} />
    </div>
  </Tooltip>
);
