import React, { createContext, useState } from "react";
import { GradingPeriodData } from "./types";

const initialState = {
  gradingPeriodData: null,
  setGradingPeriodData: () => {},
};
type GradingPeriodDataContextType = {
  gradingPeriodData: GradingPeriodData | null;
  setGradingPeriodData: (data: GradingPeriodData | null) => void;
};

export const GradingPeriodDataContext =
  createContext<GradingPeriodDataContextType>(initialState);

type GradingPeriodDataProviderProps = {
  initialGradingPeriodData: GradingPeriodData;
};

export const GradingPeriodDataProvider = ({
  initialGradingPeriodData,
  children,
}: React.PropsWithChildren<GradingPeriodDataProviderProps>) => {
  const [gradingPeriodData, setGradingPeriodData] = useState<GradingPeriodData>(
    initialGradingPeriodData
  );

  return (
    <GradingPeriodDataContext.Provider
      value={{ gradingPeriodData, setGradingPeriodData }}
    >
      {children}
    </GradingPeriodDataContext.Provider>
  );
};

export const useGradingPeriodData = () =>
  React.useContext(GradingPeriodDataContext);
