import clsx from "clsx";
import {
  FaBullseye,
  FaCheck,
  FaExclamation,
  FaMinus,
  FaPlus,
  FaQuestion,
  FaTrash,
} from "react-icons/fa";
import { GiCycle } from "react-icons/gi";
import {
  IoMdAddCircle,
  IoMdCloseCircle,
  IoMdHelpCircle,
  IoMdRemoveCircle,
  IoMdSchool,
} from "react-icons/io";
import { IoAlertCircle, IoCheckmarkCircle } from "react-icons/io5";
import {
  TbAlarmFilled,
  TbThumbUpFilled,
  TbUser,
  TbUserOff,
  TbUsers,
  TbZzz,
} from "react-icons/tb";

export type AttendanceIconSubject = "student" | "teacher" | "indicator";
export type AttendanceIconType =
  | "normal"
  | "cross"
  | "plus"
  | "minus"
  | "check"
  | "exclamation"
  | "question"
  | "snooze"
  | "multi"
  | "missing"
  | "thumbsup"
  | "thumbsdown"
  | "trash"
  | "alarm"
  | "sub";

type Props = {
  className?: string;
  type: AttendanceIconType;
  subject: AttendanceIconSubject;
};

export const AttendanceIcon = ({ className, subject, type }: Props) => (
  <div className={className}>
    {subject === "indicator" ? (
      getIcon(type, subject)
    ) : (
      <SubIconWrapper type={type}>
        <SubjectWrapper subject={subject} type={type}>
          {getIcon(type, subject)}
        </SubjectWrapper>
      </SubIconWrapper>
    )}
  </div>
);

const getIcon = (type: AttendanceIconType, subject: AttendanceIconSubject) => {
  if (subject === "indicator") {
    switch (type) {
      case "cross":
        return <IoMdCloseCircle className="w-full h-full" />;
      case "plus":
        return <IoMdAddCircle className="w-full h-full" />;
      case "minus":
        return <IoMdRemoveCircle className="w-full h-full" />;
      case "check":
        return <IoCheckmarkCircle className="w-full h-full" />;
      case "exclamation":
        return <IoAlertCircle className="w-full h-full" />;
      case "question":
        return <IoMdHelpCircle className="w-full h-full" />;
      case "snooze":
        return <TbZzz className="w-full h-full" />;
      case "multi":
        return <TbUsers className="w-full h-full" />;
      case "missing":
        return <TbUserOff className="w-full h-full" />;
      case "thumbsup":
        return <TbThumbUpFilled className="w-full h-full" />;
      case "thumbsdown":
        return (
          <TbThumbUpFilled className="w-full h-full rotate-180 -scale-x-100" />
        );
      case "trash":
        return <FaTrash className="w-full h-full" />;
      case "alarm":
        return <TbAlarmFilled className="w-full h-full" />;
      case "sub":
        return <GiCycle className="w-full h-full" />;
      case "normal":
      default:
        return <FaBullseye className="w-full h-full" />;
    }
  } else if (subject === "teacher" || subject === "student") {
    switch (type) {
      case "multi":
        return <TbUsers className="w-full h-full" />;
      case "missing":
        return <TbUserOff className="w-full h-full" />;
      case "normal":
      case "cross":
      case "plus":
      case "minus":
      case "check":
      case "exclamation":
      case "question":
      case "snooze":
      case "multi":
      case "missing":
      case "thumbsup":
      case "thumbsdown":
      case "trash":
      case "alarm":
      case "sub":
      default:
        return <TbUser className="w-full h-full" />;
    }
  }
};

type SubjectWrapperProps = {
  subject: AttendanceIconSubject;
  type: AttendanceIconType;
  children: React.ReactNode;
};

function SubjectWrapper({ subject, type, children }: SubjectWrapperProps) {
  if (subject === "student") {
    return (
      <div className="relative scale-y-[0.93] scale-x-[0.9] origin-bottom">
        {children}
        <IoMdSchool
          className={clsx(
            "absolute -top-[6%] w-[50%] h-[50%] origin-center",
            type === "multi" ? "left-[12%]" : "left-[25%]",
            type === "missing" ? "scale-x-[1.4]" : "-scale-x-[1.4]"
          )}
        />
      </div>
    );
  }

  return <>{children}</>;
}

type TypeWrapperProps = {
  type: AttendanceIconType;
  children: React.ReactNode;
};
function SubIconWrapper({ type, children }: TypeWrapperProps) {
  if (type === "normal" || type === "multi" || type === "missing") {
    return <>{children}</>;
  }

  let subIcon: React.ReactNode = null;

  switch (type) {
    case "cross":
      subIcon = <FaPlus className="w-full h-full rotate-45" />;
      break;
    case "plus":
      subIcon = <FaPlus className="w-full h-full" />;
      break;
    case "minus":
      subIcon = <FaMinus className="w-full h-full" />;
      break;
    case "check":
      subIcon = <FaCheck className="w-full h-full" />;
      break;
    case "exclamation":
      subIcon = <FaExclamation className="w-full h-full" />;
      break;
    case "question":
      subIcon = <FaQuestion className="w-full h-full" />;
      break;
    case "snooze":
      subIcon = <TbZzz className="w-[120%] h-[120%]" />;
      break;
    case "thumbsup":
      subIcon = <TbThumbUpFilled className="w-full h-full" />;
      break;
    case "thumbsdown":
      subIcon = (
        <TbThumbUpFilled className="w-full h-full rotate-180 -scale-x-100" />
      );
      break;
    case "trash":
      subIcon = <FaTrash className="w-[90%] h-[90%]" />;
      break;
    case "alarm":
      subIcon = <TbAlarmFilled className="w-[120%] h-[120%]" />;
      break;
    case "sub":
      subIcon = (
        <GiCycle className="absolute w-[110%] h-[110%] left-[15%] -top-[15%]" />
      );
      break;
  }

  return (
    <div className="relative -left-[15%] w-full h-full">
      {children}
      <div className="absolute w-[35%] h-[35%] -right-[9%] top-[34%]">
        {subIcon}
      </div>
    </div>
  );
}
