import { CohortSessionStudentAttendanceStatus } from "@generated/graphql";
import clsx from "clsx";
import { Badge, Icon, Tooltip } from "components/shared";
import { AttendanceStatusPillButton } from "components/shared/AttendanceGrades/components/AttendanceStatusPillButton";
import {
  ATTENDANCE_READONLY_WIDTH,
  NOTES_MIN_WIDTH,
  SCREEN_WIDTH,
} from "../..";

type DisplayNameProps = { displayName?: string | null };

export const ReadOnlyDisplayName = ({ displayName }: DisplayNameProps) => (
  <div
    style={{ width: `${SCREEN_WIDTH}px` }}
    className={clsx(
      "flex text-sm justify-center flex-wrap",
      displayName ? "text-slate-600 font-semibold" : "text-gray-400"
    )}
  >
    {displayName ?? "No Match"}
  </div>
);

type AttendanceProps = { status: CohortSessionStudentAttendanceStatus };

export const ReadOnlyAttendanceStatus = ({ status }: AttendanceProps) => (
  <div
    className="flex justify-center"
    style={{ width: `${ATTENDANCE_READONLY_WIDTH}px` }}
  >
    <AttendanceStatusPillButton status={status} />
  </div>
);

type EvaluationProps = { notes?: string | null };

export const ReadOnlyEvaluationNotes = ({ notes }: EvaluationProps) => (
  <div
    className="flex flex-1 flex-wrap pr-3"
    style={{ minWidth: `${NOTES_MIN_WIDTH}px` }}
  >
    {notes ? notes : <div className="text-sm text-gray-300">No notes...</div>}
  </div>
);

export const noShowDayTooltip = (isEngagementNoShowDay: boolean) => (
  <Tooltip
    tooltipProps={{ place: "right" }}
    className="relative flex w-5 cursor-pointer"
    content={
      <div className="w-[280px] leading-4 text-center">
        {`${
          isEngagementNoShowDay ? "This is an Engagement" : "This is a Cohort"
        } student no-show day, so attendance is not recorded for this day.`}
      </div>
    }
  >
    <Badge className="flex bg-gray-200 text-gray-900 font-light text-2xs gap-1 uppercase">
      <Icon icon="student" size={5} color="text-gray-600" />
      {`${isEngagementNoShowDay ? "Engagement" : "Cohort"}`} no show day
    </Badge>
  </Tooltip>
);

export const noRecordBadge = (record: string) => (
  <Badge className="flex bg-orange-200 text-gray-900 font-light text-2xs gap-[4px] w-fit uppercase">
    <Icon icon="warning" size={4} color="text-gray-600" />
    {`No ${record} record generated`}
  </Badge>
);

export const notHappenedYetBadge = () => (
  <Badge className="flex bg-gray-200 text-gray-900 font-light text-2xs gap-[4px] w-fit">
    <Icon icon="sched" size={4} color="text-gray-600" />
    {`This session hasn't occurred yet!`}
  </Badge>
);
