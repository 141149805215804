import { gql } from "@apollo/client";
import {
  SessionReportEvaluationTableDetails,
  SessionReportStudentEvaluationRow,
} from "@shared/SessionReports/types";
import { getScrollbarStyle } from "@utils/styleStrings";
import { clsx } from "clsx";
import { useMemo } from "react";
import { SessionStudentEvaluationTableHeader } from "./components/SessionStudentEvaluationTableHeader";
import { SessionStudentEvaluationTableRow } from "./components/SessionStudentEvaluationTableRow/SessionStudentEvaluationTableRow";
import {
  ATTENDANCE_MIN_WIDTH,
  ATTENDANCE_READONLY_WIDTH,
  BADGE_ATTENDANCE_WIDTH,
  BADGE_GRADING_WIDTH,
  GRADING_MIN_WIDTH,
  GRADING_READONLY_WIDTH,
} from "./constants";
import {
  ATTENDANCE_FRAGMENT,
  GRADING_FRAGMENT,
  STUDENT_FRAGMENT,
} from "./fragments";

SessionStudentEvaluationTable.fragments = {
  tutorDashboardEvent: gql`
    fragment SessionStudentEvaluationTable_TutorDashboardEvent on TutorDashboardEvent {
      subject
      cacheKey
      cohortId
      subSubject
      engagementId
      startDateTime
      cohortSessionId
      isCohortNoShowDay
      isCohortAssessmentDay
      isEngagementNoShowDay
      isEngagementAssessmentDay
      cohortSession {
        id
        studentAttendanceEntries {
          ...SessionStudentEvaluationTable_StudentAttendanceRecord
        }
        studentGradingEntries {
          ...SessionStudentEvaluationTable_StudentGradingRecord
        }
      }
    }
    ${GRADING_FRAGMENT}
    ${ATTENDANCE_FRAGMENT}
  `,
  tutorDashboardCohort: gql`
    fragment SessionStudentEvaluationTable_TutorDashboardCohort on TutorDashboardCohort {
      id
      activeStudents {
        id
        studentId
        ...SessionStudentEvaluationTable_TutorDashboardStudent
      }
    }
    ${STUDENT_FRAGMENT}
  `,
  tutorDashboardEngagement: gql`
    fragment SessionStudentEvaluationTable_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      productType
      enableStudentGrading
    }
  `,
};

type Props = {
  readOnly?: boolean;
  highlightIncomplete?: boolean;
  evaluationDetails: SessionReportEvaluationTableDetails;
  studentEvaluationRows: SessionReportStudentEvaluationRow[];
};

export function SessionStudentEvaluationTable({
  readOnly = false,
  evaluationDetails,
  highlightIncomplete,
  studentEvaluationRows,
}: Props) {
  const { attendanceColWidth, gradesColWidth } = useMemo(() => {
    const hasAttendance = studentEvaluationRows.some((row) => !!row.attendance);
    const hasGrades = studentEvaluationRows.some((row) => !!row.grading);

    return {
      attendanceColWidth: readOnly
        ? ATTENDANCE_READONLY_WIDTH
        : hasAttendance
        ? ATTENDANCE_MIN_WIDTH
        : BADGE_ATTENDANCE_WIDTH,
      gradesColWidth: readOnly
        ? GRADING_READONLY_WIDTH
        : hasGrades
        ? GRADING_MIN_WIDTH
        : BADGE_GRADING_WIDTH,
    };
  }, [readOnly, studentEvaluationRows]);

  return (
    <div
      className={clsx(
        "block w-full h-full overflow-x-auto",
        getScrollbarStyle("barOnly")
      )}
    >
      <div
        className={clsx(
          "flex flex-col min-w-full w-fit border rounded-lg",
          "border-gray-300 divide-y divide-gray-300"
        )}
      >
        <SessionStudentEvaluationTableHeader
          readOnly={readOnly}
          gradesColWidth={gradesColWidth}
          attendanceColWidth={attendanceColWidth}
          isGradingEnabled={evaluationDetails.isGradingEnabled}
        />

        {studentEvaluationRows.map((row, i) => (
          <SessionStudentEvaluationTableRow
            key={row.studentId}
            readOnly={readOnly}
            studentEvaluationRow={row}
            gradesColWidth={gradesColWidth}
            evaluationDetails={evaluationDetails}
            attendanceColWidth={attendanceColWidth}
            zIndex={studentEvaluationRows.length - i}
            highlightIncomplete={highlightIncomplete}
            className={clsx(
              i == studentEvaluationRows.length - 1 && "rounded-b-lg"
            )}
          />
        ))}
      </div>
    </div>
  );
}
