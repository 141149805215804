import { Checkbox, Tooltip } from "@shared";
import clsx from "clsx";

type Props = {
  checked: boolean;
  disabled?: boolean;
  onChange: (newState: boolean) => void;
};

export const AbsentFromAssessmentCheckbox = ({
  checked,
  disabled = false,
  onChange,
}: Props) => (
  <Tooltip
    disabled={disabled}
    tooltipProps={{ place: "left-start" }}
    content={
      <div className="flex flex-col max-w-[350px] text-sm text-center">
        <h1 className="text-lg">Absent from Assessment</h1>
        Toggle whether you want this student to be absent from assessment for
        this specific grading criteria.
        <p
          className={clsx(
            "text-base",
            checked ? "text-rose-500" : "text-emerald-400"
          )}
        >{`(Grading is ${checked ? "Disabled" : "Enabled"})`}</p>
      </div>
    }
  >
    <Checkbox
      checked={checked}
      disabled={disabled}
      inputClassName="h-6 w-6 text-neutral-500 focus:ring-neutral-500"
      onChange={() => onChange(!checked)}
    />
  </Tooltip>
);
