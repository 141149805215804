import { gql, useMutation } from "@apollo/client";
import {
  CohortAssignmentSubject,
  EditSingleStudentGradingMutation,
  EditSingleStudentGradingMutationVariables,
  SessionStudentEvaluationTable_StudentGradingRecordFragment,
} from "@generated/graphql";
import { GradingPanel } from "@shared/AttendanceGrades/components/GradingPanel/GradingPanel";
import { triggerErrorToast } from "components/shared";
import { negOneIsNull } from "components/shared/AttendanceGrades/utils";
import {
  noRecordBadge,
  noShowDayTooltip,
  notHappenedYetBadge,
} from "../../helpers";
import { getUpdateGradingFields } from "./utils";

export type StudentGrades =
  SessionStudentEvaluationTable_StudentGradingRecordFragment;

const EDIT_SINGLE_STUDENT_GRADING = gql`
  mutation EditSingleStudentGrading(
    $input: [EditCohortSessionStudentGradingInput!]!
  ) {
    editCohortSessionStudentGrading(input: $input) {
      id
      readingGrade
      classworkGrade
      cohortSessionId
      languageArtsGrade
      participationGrade
      readingAbsentFromAssessment
      classworkAbsentFromAssessment
      languageArtsAbsentFromAssessment
    }
  }
`;

type Props = {
  studentId: string;
  startDateTime: Date;
  gradesColWidth: number;
  cohortSessionId: string;
  studentDidAttend: boolean;
  isCohortNoShowDay: boolean;
  isEngagementNoShowDay: boolean;
  subject: CohortAssignmentSubject;
  studentGradingIsEnabled: boolean;
  hasStudentAttendanceEntry: boolean;
  studentGradingEntry: SessionStudentEvaluationTable_StudentGradingRecordFragment | null;
};

export const StudentGradingSection = ({
  subject,
  startDateTime,
  gradesColWidth,
  studentDidAttend,
  isCohortNoShowDay,
  studentGradingEntry,
  isEngagementNoShowDay,
  studentGradingIsEnabled,
  hasStudentAttendanceEntry,
}: Props) => {
  const [editSingleStudentGradingMutation] = useMutation<
    EditSingleStudentGradingMutation,
    EditSingleStudentGradingMutationVariables
  >(EDIT_SINGLE_STUDENT_GRADING, {
    update: (cache, { data }) => {
      if (data) {
        const updatedGrading = data.editCohortSessionStudentGrading[0];
        const fields = getUpdateGradingFields(updatedGrading);
        cache.modify({
          id: `TutorDashboardCohortSessionStudentGrading:${updatedGrading.id}`,
          fields,
        });
      }
    },
    onError: (error) =>
      triggerErrorToast({
        message: "Looks like something went wrong.",
        sub: "We weren't able to update this student's grades successfully.",
        log: error,
      }),
  });

  const updateStudentGrade = async (grades: StudentGrades) => {
    if (!studentGradingEntry) return;
    await editSingleStudentGradingMutation({
      variables: {
        input: [
          {
            id: studentGradingEntry.id,
            participationGrade: negOneIsNull(grades.participationGrade),
            languageArtsGrade: negOneIsNull(grades.languageArtsGrade),
            languageArtsAbsentFromAssessment:
              grades.languageArtsAbsentFromAssessment,
            readingGrade: negOneIsNull(grades.readingGrade),
            readingAbsentFromAssessment: grades.readingAbsentFromAssessment,
            classworkGrade: negOneIsNull(grades.classworkGrade),
            classworkAbsentFromAssessment: grades.classworkAbsentFromAssessment,
          },
        ],
      },
    });
  };

  if (!studentGradingIsEnabled) return null;

  const renderContent = () => {
    if (!studentGradingEntry || !hasStudentAttendanceEntry)
      return new Date(startDateTime).getTime() > Date.now()
        ? notHappenedYetBadge()
        : noRecordBadge("grading");
    if (isEngagementNoShowDay || isCohortNoShowDay)
      return noShowDayTooltip(isEngagementNoShowDay);

    return (
      <GradingPanel
        subject={subject}
        className="pr-2"
        key={studentGradingEntry.id}
        disableGrading={!studentDidAttend}
        studentGrades={studentGradingEntry}
        updateStudentGrade={updateStudentGrade}
      />
    );
  };

  return (
    <div
      className="flex flex-1 justify-start py-[6px]"
      style={{ minWidth: `${gradesColWidth}px` }}
    >
      {renderContent()}
    </div>
  );
};
