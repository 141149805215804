import { ExclusionISODays } from "@shared/AttendanceGrades/types";
import { StudentGradesFilters, StudentGradesSortType } from "./types";

export const initExclusionISODays: ExclusionISODays = {
  engagementNoShowISODays: [],
  engagementAssessmentISODays: [],
  cohortsExclusionISODays: {},
  engagementNoTutoringISODays: {},
};

export const initIsEngagementDays = {
  isEngagementNoShowDay: false,
  isEngagementAssessmentDay: false,
  isEngagementNoTutoringDay: [],
};

export const initIsCohortDays = {
  isCohortNoShowDay: false,
  isCohortAssessmentDay: false,
};

export const initSort = {
  isSortedDesc: false,
  sortType: StudentGradesSortType.Student,
};

export const initFilters: StudentGradesFilters = {
  endDateFilter: null,
  startDateFilter: null,
  cohortIdFilter: null,
  studentGradeFilter: null,
  showRemovedStudentsFilter: false,
};

export const COLUMN_MAP: StudentGradesSortType[] = [
  StudentGradesSortType.Student,
  StudentGradesSortType.StudentGrade,
  StudentGradesSortType.Cohorts,
  StudentGradesSortType.Attendance,
  StudentGradesSortType.Grades,
];

export const STUDENT_COL_MIN_WIDTH = 120;
export const STUDENT_GRADE_COL_MIN_WIDTH = 70;
export const ATTENDANCE_COL_MIN_WIDTH = 90;
export const GRADES_COL_MIN_WIDTH = 180;

// Side Menu Width + (Padding * 5)
export const SIDE_MENU_PADDING = 240 + 16 * 5;
