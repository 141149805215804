/**
 * Reverses any array if the condition is true. Else, returns the array as-is.
 * @param arr
 * @param condition
 * @returns
 */
export function conditionalReverse<T>(arr: T[], condition: boolean) {
  if (condition) arr.reverse();
  return arr;
}
